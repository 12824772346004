<template>
  <div>
    <div class="text-gray-800 bg-white mt-8 md:mt-[7.5%]">
      <div class="mx-auto text-center">
        <h1 class="mb-4 text-3xl font-bold md:text-4xl">{{ header }}</h1>
        <p class="max-w-2xl mx-4 mb-8 text-base md:mx-auto md:text-lg">{{ mission }}</p>
        <div class="flex flex-col items-center md:flex-row md:justify-center md:space-x-[7.5%]">
          <div v-for="person in team" :key="person.id" class="mb-8 text-center md:mb-0">
            <img
              class="w-40 h-40 mx-auto mb-4 rounded-full md:w-48 md:h-48"
              :src="person.imageUrl"
              :alt="`Portrait of ${person.name}`"
              loading="lazy"
            />
            <h1 class="text-lg font-semibold md:text-3xl">{{ person.name }}</h1>
            <!-- <h1 class="text-lg italic md:text-lg">{{ person.initials }}</h1> -->
            <p class="text-lg font-bold text-[#00C8B1] md:text-4xl">{{ person.role }}</p>
            <br />
            <div class="flex justify-center my-2 space-x-4">
              <a :href="person.linkedin" class="text-blue-600">
                <img class="w-8 h-8" src="/icons/linkedin_icon.png" loading="lazy" />
              </a>
              <a :href="person.email" class="text-blue-600">
                <img class="w-8 h-8" src="/icons/email_icon.png" loading="lazy" />
              </a>
            </div>
            <br />
            <p class="text-xl italic text-gray-600">Formerly:</p>
            <p class="font-semibold text-gray-800">{{ person.previousTitle }}</p>
            <p class="font-bold text-gray-800">@<br />{{ person.previousCompany }}</p>
            <!-- <p class="italic text-gray-800">{{ person.previousRole }}</p> -->
          </div>
        </div>
        <div class="relative mt-8 md:mt-[10%]">
          <!-- Image container -->
          <div class="bg-white">
            <img class="w-full" src="/images/CPH.png" alt="Copenhagen" loading="lazy" />
            <div class="h-16 md:h-[15vh] bg-black"></div>
          </div>
          <!-- Text overlay -->
          <div class="absolute inset-x-0 bottom-0 text-white transform translate-y-[-40%]">
            <h2 class="text-4xl md:text-8xl">Copenhagen</h2>
            <p class="text-xl md:text-3xl">Denmark</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'

const header = 'The Team'
const mission =
  'AlterEcho was built to make a difference where it matters most. We empower operators to work smarter, with less stress, while ensuring that patients receive medicines crafted with the pinnacle of today’s technology. Our mission is to simplify, optimize, and elevate the standards of aseptic manufacturing.'

const team = ref([
  {
    id: 1,
    imageUrl: '/images/MM.png',
    name: 'Marco Molinari',
    initials: 'Mr. Data Clickolini',
    role: 'CEO',
    linkedin: '#',
    email: 'MM@AlterEcho.IO',
    previousTitle: 'Autonomous Systems Engineer',
    previousRole:
      'Senior “Let’s Optimize This” Strategist @ Somewhere Between Draft 5 and The ‘Final’ Final',
    previousCompany: 'Novo Nordisk'
  },
  {
    id: 2,
    imageUrl: '/images/SMV.png',
    name: 'Søren Myhre Voss',
    initials: 'Mr. Algorithm McByte',
    role: 'CTO',
    linkedin: '#',
    email: 'SMV@AlterEcho.IO',
    previousTitle: 'Robotics & Automation Engineer',
    previousRole: 'Lead “Chief of Things That Go Beep” @ Definitely Not a Secret Robot Factory',
    previousCompany: 'Novo Nordisk'
  }
])
</script>

<style scoped>
#map {
  height: 100%;
  width: 100%;
}
</style>
