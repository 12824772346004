<template>
  <div
    class="flex items-center justify-center bg-black text-white px-4 md:px-[10%] md:w-screen md:h-screen"
  >
    <div class="flex flex-col w-full h-full justify-evenly">
      <!-- Top Spacer: 15% of parent's height on desktop -->
      <div class="md:h-[10%]"></div>

      <!-- Content Section -->
      <div class="md:h-[20%] flex flex-col items-center justify-around w-full md:flex-row">
        <div
          v-for="item in items"
          :key="item.id"
          class="flex flex-col items-center justify-between w-full px-4 mb-8 text-center md:w-1/4 md:mb-0"
        >
          <img class="w-3/5 h-auto mb-4" :src="item.imageUrl" :alt="item.alt" loading="lazy" />
          <h2 class="text-2xl font-bold md:text-3xl">{{ item.title }}</h2>
          <p class="w-4/5 text-lg">{{ item.subtitle }}</p>
          <!-- <p class="w-4/5 text-base text-justify">{{ item.description }}</p> -->
        </div>
      </div>

      <!-- Bottom Spacer: 10% of parent's height on desktop -->
      <div class="md:h-[15%]"></div>

      <!-- Statistics Section -->
      <div class="md:h-[10%] flex flex-col justify-around items-start w-full md:flex-row">
        <div
          v-for="stat in stats"
          :key="stat.id"
          class="flex flex-col items-center justify-start w-full mb-6 text-center md:w-1/4 md:mb-0"
        >
          <div class="text-5xl font-bold md:text-6xl">{{ stat.value }}</div>
          <div class="text-xl md:text-4xl" v-html="stat.label"></div>
        </div>
      </div>

      <!-- Additional Bottom Spacer if needed -->
      <div class="md:h-[15%]"></div>

      <!-- Cards grid -->
      <div
        class="md:h-[20%] grid w-full h-full grid-cols-2 md:grid-cols-3 xl:grid-cols-6 gap-3 justify-items-center items-center"
      >
        <div class="items-center h-full text-3xl">
          <!-- UN Sustainability Goals Title -->
          AlterEcho contributes to<br />
          <p class="text-4xl font-bold text-right">UN<br />Sustainability<br />Goals</p>
        </div>

        <!-- Loop through UNgoals -->
        <FlipCard v-for="goal in UNgoals" :key="goal.id" class="flex flex-col p-4 w-[90%]">
          <!-- Front content -->
          <template #front>
            <div class="flex flex-col items-center justify-center h-full text-center">
              <img
                :src="goal.imageUrl"
                class="object-contain w-full"
                loading="lazy"
                alt="Challenge Icon"
              />
            </div>
          </template>

          <!-- Back content -->
          <template #back>
            <div
              class="flex items-center justify-center h-full px-4 py-4 text-xs text-center text-black bg-white md:text-base"
            >
              {{ goal.description }}
            </div>
          </template>
        </FlipCard>
      </div>

      <div class="md:h-[5%]"></div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import FlipCard from './FlipCard.vue'

interface Item {
  id: number
  imageUrl: string
  alt: string
  title: string
  subtitle: string
  description: string
}

interface Stat {
  id: number
  value: string
  label: string
}

interface Goal {
  id: number
  imageUrl: string
  description: string
}

const items = ref<Item[]>([
  {
    id: 1,
    imageUrl: '/images/LessIsMore.png',
    alt: 'Simplified process illustration',
    title: 'LESS is MORE',
    subtitle: 'Simplify processes, reduce human intervention, and minimize contamination risks.',
    description:
      'AlterEcho streamlines operations by eliminating unnecessary steps, resulting in cleaner and more efficient production.'
  },
  {
    id: 2,
    imageUrl: '/images/WasteNoTime.png',
    alt: 'Clock on fire illustration',
    title: 'WASTE no TIME',
    subtitle: 'Immediate responses, quicker interventions, and seamless integration.',
    description:
      'AlterEcho ensures that no time is wasted, significantly boosting overall equipment efficiency and productivity.'
  },
  {
    id: 3,
    imageUrl: '/images/DoItRight.png',
    alt: 'Precision hand illustration',
    title: 'DO it RIGHT',
    subtitle: 'Achieve precision and reliability with every operation.',
    description:
      "AlterEcho's advanced technology ensures tasks are executed flawlessly, reducing waste and enhancing overall production quality."
  }
])

const stats = ref<Stat[]>([
  { id: 1, value: '-95%', label: 'Contamination<br/>Chances' },
  { id: 2, value: '+30%', label: 'Overall<br/>Equipment<br/>Efficiency' },
  { id: 3, value: '+20%', label: 'Recovered<br/>Revenue' },
  { id: 4, value: '-45%', label: 'Wasted<br/>Plastic<br/>Disposables' }
])

const UNgoals = ref<Goal[]>([
  {
    id: 1,
    imageUrl: '/images/E-WEB-Goal-03.png',
    description:
      "Setting new standards in compliance while enhancing aseptic operators' health and wellbeing."
  },
  {
    id: 2,
    imageUrl: '/images/E-WEB-Goal-08.png',
    description:
      'Transforming aseptic manufacturing with emerging technologies to boost efficiency.'
  },
  {
    id: 3,
    imageUrl: '/images/E-WEB-Goal-09.png',
    description: 'Simplifying complex pharmaceutical infrastructure through innovative solutions.'
  },
  {
    id: 4,
    imageUrl: '/images/E-WEB-Goal-10.png',
    description:
      'Fostering inclusivity by creating roles accessible to all, regardless of gender and disabilities.'
  },
  {
    id: 5,
    imageUrl: '/images/E-WEB-Goal-12.png',
    description: 'Eliminating disposable gowning waste by removing humans from cleanrooms.'
  }
])
</script>

<style scoped></style>
