<template>
  <div class="w-full mt-20">
    <div class="w-full text-gray-800 bg-white">
      <div
        class="flex flex-col items-center justify-center px-4 py-6 md:px-[10%] md:py-[5%] md:h-full"
      >
        <div class="w-full md:h-[50%]">
          <!-- Main Title -->
          <h1 class="mb-8 text-4xl text-center md:mb-12 md:text-6xl md:text-right">
            {{ mainTitle }}
          </h1>
          <div class="flex flex-col items-center justify-center w-full md:flex-row md:h-full">
            <div
              class="flex flex-col items-center justify-center w-full px-4 md:w-1/2 md:px-20 blur-[5px]"
            >
              <!-- Subtitles and Descriptions -->
              <div class="flex items-start justify-start mb-6 md:flex-1">
                <!-- Top left -->
                <div class="w-full md:w-2/3">
                  <h1 class="mb-2 text-xl font-bold">{{ subtitle1 }}</h1>
                  <p class="mb-4">
                    {{ description1.part1 }}
                    <span class="font-bold">{{ description1.bold1 }}</span>
                    {{ description1.part2 }}
                    <span class="font-bold">{{ description1.bold2 }}</span>
                    {{ description1.part3 }}
                  </p>
                </div>
              </div>
              <div class="flex items-center justify-center mb-6 md:flex-1">
                <!-- Center center -->
                <div class="w-full text-center md:w-2/3">
                  <h1 class="mb-2 text-xl font-bold">{{ subtitle2 }}</h1>
                  <p class="mb-4">
                    {{ description2.part1 }}
                    <span class="font-bold">{{ description2.bold }}</span>
                    {{ description2.part2 }}
                  </p>
                </div>
              </div>
              <div class="flex items-end justify-end md:flex-1">
                <!-- Bottom right -->
                <div class="w-full text-right md:w-2/3">
                  <h1 class="mb-2 text-xl font-bold">{{ subtitle3 }}</h1>
                  <p>
                    {{ description3.part1 }}
                    <span class="font-bold">{{ description3.bold }}</span>
                    {{ description3.part2 }}
                  </p>
                </div>
              </div>
            </div>
            <div class="w-full mt-8 md:w-[45%] md:mt-0">
              <img class="object-cover w-full h-auto p-4" :src="blur_image" alt="" loading="lazy" />
            </div>
          </div>
        </div>
        <div class="flex items-center justify-center w-full pt-10 text-2xl text-center">
          <p>
            Would you like to learn about AlterEcho's technology?<br />Contact us at
            <b>info@AlterEcho.IO</b>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const mainTitle = "AlterEcho's Technology"
// Subtitles
const subtitle1 = 'info@AlterEcho.IO'
const subtitle2 = 'MM@AlterEcho.IO'
const subtitle3 = 'SMV@AlterEcho.IO'
const blur_image = '/images/video_blurred2.png'

// Descriptions with Bold Text
const description1 = {
  part1: 'This section is top secret! For more details, please email us at',
  bold1: 'info@AlterEcho.IO',
  part2: 'or',
  bold2: 'MM@AlterEcho.IO',
  part3: 'to unlock exclusive information without compromising safety protocols.'
}

const description2 = {
  part1: 'Confidential technology in action. To learn more about our capabilities, reach out via',
  bold: 'SMV@AlterEcho.IO',
  part2: 'and discover seamless integration tailored to your needs.'
}

const description3 = {
  part1: 'Sensitive collaboration in progress. For inquiries, contact us at',
  bold: 'info@AlterEcho.IO',
  part2: 'and experience unparalleled efficiency and safety through our advanced solutions.'
}
</script>

<style scoped></style>
