<template>
  <div class="relative inline-block bg-transparent group" style="perspective: 800px">
    <div
      class="transition-transform duration-700 flip-card-inner transform-style-preserve-3d group-hover:flip"
    >
      <!-- Front Side -->
      <div class="bg-white shadow-2xl flip-card-front">
        <slot name="front"></slot>
      </div>

      <!-- Back Side -->
      <div class="shadow-2xl flip-card-back">
        <slot name="back"></slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: 'FlipCard'
}
</script>

<style scoped>
.flip-card-inner {
  display: grid;
  transform-style: preserve-3d;
  transition: transform 0.5s;
}

.group:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  backface-visibility: hidden;
  /* Ensure both faces occupy the same grid area */
  grid-area: 1 / 1 / 2 / 2;
}

.flip-card-back {
  transform: rotateY(180deg);
}
</style>
