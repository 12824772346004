<template>
  <div class="w-full h-full">
    <div class="w-full h-full text-gray-800 bg-white">
      <div
        class="flex flex-col items-center justify-center h-full px-4 py-10 md:px-[10%] md:py-[10%]"
      >
        <div class="w-full">
          <!-- Main Title -->
          <h1 class="mb-8 text-4xl text-center md:text-6xl md:text-right">{{ mainTitle }}</h1>
          <div class="flex flex-col items-center justify-center w-full md:flex-row md:h-auto">
            <!-- Subtitles and Descriptions -->
            <div class="flex flex-col items-center justify-center w-full px-4 md:w-1/2 md:px-20">
              <!-- Top left -->
              <div class="flex items-start justify-start mb-6">
                <div class="w-full">
                  <h1 class="mb-2 text-xl font-bold">{{ subtitle1 }}</h1>
                  <p class="mb-4">
                    {{ description1.part1 }}
                    <span class="font-bold">{{ description1.bold1 }}</span>
                    {{ description1.part2 }}
                    <span class="font-bold">{{ description1.bold2 }}</span>
                    {{ description1.part3 }}
                  </p>
                </div>
              </div>
              <!-- Center center -->
              <div class="flex items-center justify-center mb-6">
                <div class="w-full text-center">
                  <h1 class="mb-2 text-xl font-bold">{{ subtitle2 }}</h1>
                  <p class="mb-4">
                    {{ description2.part1 }}
                    <span class="font-bold">{{ description2.bold }}</span>
                    {{ description2.part2 }}
                  </p>
                </div>
              </div>
              <!-- Bottom right -->
              <div class="flex items-end justify-end">
                <div class="w-full text-right">
                  <h1 class="mb-2 text-xl font-bold">{{ subtitle3 }}</h1>
                  <p>
                    {{ description3.part1 }}
                    <span class="font-bold">{{ description3.bold }}</span>
                    {{ description3.part2 }}
                  </p>
                </div>
              </div>
            </div>
            <!-- Video Section -->
            <div class="w-full mt-8 md:w-1/2 md:mt-0">
              <video
                class="object-cover w-full h-auto"
                src="/videos/AlterEcho_Render3.mp4"
                autoplay
                muted
                loop
                disablePictureInPicture
              >
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const mainTitle = "AlterEcho's Technology"

// Subtitles
const subtitle1 = 'Remote Embodiment'
const subtitle2 = 'Universal I/O'
const subtitle3 = 'Cybernetics Synergy'

// Descriptions with Bold Text
const description1 = {
  part1:
    'Our technology allows operators to immerse themselves fully in controlling robotic systems, using',
  bold1: 'high-definition video',
  part2: 'and',
  bold2: 'real-time motion tracking',
  part3:
    'to interact with environments remotely. It ensures precise operations in sterile or hazardous settings without requiring physical presence.'
}

const description2 = {
  part1:
    "The system's flexibility shines through its ability to interface with any input or output, regardless of brand or configuration. Whether you prefer remote gloves, specific joysticks, or any control device, this technology ensures",
  bold: 'seamless operation across all platforms',
  part2: 'to suit the specific needs of any workflow.'
}

const description3 = {
  part1: 'By seamlessly blending human intuition with robotics precision, this technology enables',
  bold: 'smooth collaboration between operators and machines.',
  part2:
    'It optimizes workflow, enhancing both efficiency and safety through synchronized interaction.'
}
</script>

<style scoped></style>
