<template>
  <div class="flex flex-col items-center w-full p-4 bg-white md:p-8">
    <!-- Header section -->
    <header class="flex flex-col items-center justify-center w-full mb-8 text-center md:mb-12">
      <h1 class="text-2xl font-normal md:text-3xl lg:text-4xl">Challenges in</h1>
      <h2 class="mt-2 text-3xl font-bold md:text-5xl lg:text-6xl">ASEPTIC MANUFACTURING</h2>
    </header>

    <!-- Cards grid -->
    <div class="grid w-full h-full grid-cols-2 gap-3 md:grid-cols-3 xl:grid-cols-6 md:gap-6">
      <!-- Loop through challenges -->
      <FlipCard
        v-for="challenge in challenges"
        :key="challenge.id"
        class="flex flex-col p-3 rounded-lg"
      >
        <!-- Front content -->
        <template #front>
          <div class="flex flex-col items-center justify-between h-full py-4 text-center">
            <img
              :src="challenge.image"
              class="object-contain w-3/4 max-w-[150px] mb-4"
              loading="lazy"
              alt="Challenge Icon"
            />
            <!-- Use v-html to render HTML in the title -->
            <h3 class="text-sm md:text-lg" v-html="challenge.title"></h3>
          </div>
        </template>

        <!-- Back content -->
        <template #back>
          <div
            class="bg-[#6000ff] flex items-center justify-center h-full px-4 py-4 text-xs text-center md:text-base text-white"
          >
            {{ challenge.description }}
          </div>
        </template>
      </FlipCard>
    </div>

    <!-- Bottom spacing -->
    <div class="w-full h-8 md:h-16"></div>
  </div>
</template>

<script setup lang="ts">
import FlipCard from './FlipCard.vue'

const challenges = [
  {
    id: 1,
    title: '<b>Low Equipment Efficiency</b><br />&<br /><b>Unrealized Revenue</b>',
    description:
      'Most filling lines operate below 60% OEE, leading to suboptimal output and billions in unrealized revenue annually.',
    image: '/icons/loss_icon.png'
  },
  {
    id: 2,
    title: '<b>Contamination Risk</b><br />&<br /><b>Microbial Control</b>',
    description:
      'Even minor breaches can lead to extensive batch contamination, endangering patient safety and product efficacy.',
    image: '/icons/contamination_icon.png'
  },
  {
    id: 3,
    title: '<b>Process Validation</b><br />&<br /><b>Regulatory Compliance</b>',
    description:
      'Meeting diverse global standards demands meticulous process documentation and consistent operational adherence.',
    image: '/icons/regulatory_icon.png'
  },
  {
    id: 4,
    title: '<b>Operator Reliability</b><br />&<br /><b>Human Error</b>',
    description:
      'Operational mistakes, despite stringent protocols, continue to introduce variability and potential failures.',
    image: '/icons/error_icon.png'
  },
  {
    id: 5,
    title: '<b>Environmental Monitor</b><br />&<br /><b>Data Integrity</b>',
    description:
      'Continuous monitoring faces challenges in ensuring accurate, tamper-proof records essential for quality assurance.',
    image: '/icons/disinfection_icon.png'
  },
  {
    id: 6,
    title: '<b>Line Interventions</b><br />&<br /><b>Equipment Maintenance</b>',
    description:
      'Frequent mechanical disruptions require swift, sterile interventions to prevent downtime and contamination.',
    image: '/icons/intervention_icon.png'
  }
]
</script>
