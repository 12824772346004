<template>
  <div class="flex items-center justify-center w-full h-full mt-40 mb-20">
    <div class="flex flex-col items-center justify-center w-3/4">
      <!-- Title section -->
      <h1 class="my-2 text-5xl text-left">
        The <b>Renaissance</b> of Aseptic Pharmaceutical Manufacturing
      </h1>
      <!-- Subtitle section -->
      <p class="mb-10 text-base text-justify lg:columns-2 lg:gap-x-8">
        In 150 CE, <b>Ptolemæus' Geocentrism</b> required chaotic trajectories to maintain Earth at
        the center of the universe. In 1543, <b>Copernicus' Heliocentrism</b> re-centered it around
        the Sun, resulting in truly elegant celestial orbits. Similarly, since the 1980s, aseptic
        manufacturing has relied on <b>unoptimized processes</b>.
        <br />
        <b>AlterEcho parallels this revolutionary shift</b> by re-centering aseptic systems around
        human operators through <b>breakthrough Industry 5.0 technologies</b>. This
        <b>rebirth</b> boosts process efficiency and total revenues, while drastically improving
        operator well-being and regulatory compliance.
        <!-- In 150 CE, <b>Ptolemæus' Geocentric model</b> required increasingly complex orbital
        trajectories to maintain Earth at the universe's center. It was only in 1543 that
        <b>Copernicus' Heliocentric model</b> reimagined these chaotic orbits into elegant motions by
        placing the Sun at the center. Similarly, since the 1980s, <b>aseptic manufacturing</b> has
        burdened operators with rigid procedures for sterility.<br/><b>AlterEcho</b> parallels this
        revolutionary shift by centering systems around human operators by leveraging
        <b>emerging technologies</b>. This transformation yields higher pharmaceutical revenues, improved operator well-being, and superior regulatory
        compliance. -->
      </p>
      <!-- Video section with titles -->
      <div class="flex flex-col justify-between w-full lg:flex-row">
        <!-- First video and title -->
        <div class="flex flex-col items-center w-full lg:w-[40%]">
          <video
            class="object-cover w-full h-auto"
            src="/videos/PtolemaicIsolator.mp4"
            autoplay
            muted
            loop
            disablePictureInPicture
          >
            Your browser does not support the video tag.
          </video>
          <h2 class="mt-4 text-3xl text-center">
            <i>Traditional Isolator System</i><br />
            1980s
          </h2>
        </div>
        <!-- Second video and title -->
        <div class="flex flex-col items-center w-full lg:w-[40%]">
          <video
            class="object-cover w-full h-auto"
            src="/videos/CopernicanIsolator.mp4"
            autoplay
            muted
            loop
            disablePictureInPicture
          >
            Your browser does not support the video tag.
          </video>
          <h2 class="mt-4 text-3xl text-center"><b>AlterEcho</b><br />2025</h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped></style>
